import { defer, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import invariant from "tiny-invariant";

import QuillRenderHtml from "~/components/atoms/QuillRenderHtml";
import Typo from "~/components/atoms/Typo";
import { getSiteContent } from "~/services/siteContentService";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { getMetadata } from "~/utilities/helpers/metadata";
import { extractDomainFromRequest } from "~/utilities/helpers/requestHelper";

export type TSiteContentLoaderData = {
  siteUrl: string;
  title: string;
  content: string;
};

export const meta: MetaFunction<typeof loader> = (args) => {
  const { data, matches, params } = args;
  const { siteUrl } = { ...data };
  const { siteContent } = params || {};
  const rootMatch = matches?.find((match) => match?.id === "root");

  if (!rootMatch) {
    return [];
  }

  const { metadata } = rootMatch.data as IRouteLoaderData;

  return getMetadata({
    title: `${metadata?.title} | ${siteContent}`,
    description: metadata?.description,
    siteUrl,
    image: metadata?.ogImage,
    twitterCreator: metadata?.twitterCreator,
    twitterSite: metadata?.twitterSite,
    additionalMeta: [
      { name: "og:type", content: "website" },
      {
        name: "og:site_name",
        content: metadata?.title,
      },
    ],
  });
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const requestUrl = new URL(request.url);
  const domain = extractDomainFromRequest(request);

  const siteUrl =
    requestUrl.protocol + "//" + requestUrl.host + requestUrl.pathname;

  invariant(params.siteContent, "Missing siteContent param");
  const siteContent = await getSiteContent(
    domain,
    (params?.siteContent || "") as string
  );

  if (!siteContent) {
    throw new Response("Not Found", { status: 404 });
  }

  const loaderData: TSiteContentLoaderData = {
    siteUrl,
    title: siteContent?.title || "",
    content: siteContent?.content || "",
  };
  return defer(loaderData);
};

export default function PageDynamic() {
  const { title, content } = useLoaderData() as TSiteContentLoaderData;
  return (
    <div className="ql-snow container-fluid flex flex-col gap-10 py-12 lg:gap-8 lg:py-0">
      <Typo
        tag={ETypoTag.H2}
        variant={ETypoVariant.HEADER_36}
        color={ETypoColor.TEXT}
        className="border-b border-gableGreen25 pb-10 font-bold lg:pb-8"
      >
        {title}
      </Typo>
      <QuillRenderHtml html={content} />
    </div>
  );
}
